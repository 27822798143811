@import "core";
.modal-container.modal-auth {	
	
	&[class*="mode-"] {
		.modal-closebtn {
			opacity: 1;
		}
	}

	&.mode-intro {
		.modal-closebtn {
			.closebtn-btn {
				background-color: transparent;
				font-size: 2rem;
			}
		}

		.modal-body {
			background-color: $color-primary;
			color: $color-white;
		}
	}


	.modal-contentwrap {
		width: 98rem;
		display: flex;
	}

	.modal-closebtn {
		opacity: 0;
	}

	.modal-bannerwrap {
		width: 48rem;
		position: relative;
		overflow: hidden;
		border-top-left-radius: $radius-general;
		border-bottom-left-radius: $radius-general;
	}

		.bannerwrap-banner {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

	.modal-body {
		flex: 1 0 0;
		min-height: 75rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		//padding: 7.2rem 8rem 5.1rem 10rem;
		padding: 3.2rem 8rem 1.1rem 10rem;

		@include media {
			min-height: auto;
			justify-content: flex-start;
			padding: 11rem 1.8rem 12rem;
		}
	}

	.auth-title {
		@include font-bold;
		display: block;
		margin-bottom: 3rem;
		font-size: 3.2rem;
	}

	.auth-phonemessage {
		font-size: 1.4rem;
		color: $color-text-light;
	}
	
	.auth-feedback {
		margin-top: 1.2rem;
	}

	.auth-pininput {
		margin-top: 1.2rem;
	}

	.auth-nav {
		text-align: center;
		padding-top: 2.4rem;
		color: $color-text-light;

		.hyperlink {
			color: $color-primary;
		}
	}

	.auth-agreements {
		padding-top: 2.1rem;
		padding-bottom: 2.1rem;
	}

		.agreements-input {
			+ .agreements-input {
				margin-top: .8rem;
			}
		}

	// .auth-pinform {}

		.pinform-submit {
			margin-top: 2.7rem;
		}

	.auth-intro {

	}

		.intro-separator {
			display: block;
			font-size: 1.4rem;
			opacity: .7;
			margin: 2.5rem 0;
			text-align: center;
		}
}