@import "core";
.pininput {
	&.error {
		.pininput-char {
			border-color: $color-error;
		}
	}
	
	
	.pininput-charwrap {
		display: flex;
	}

	.pininput-hiddeninput {
		opacity: 0;
		pointer-events: none;
		position: absolute;
		left: 0;
		width: 0;
		height: 0;
		top: 0;
	}
	
	.pininput-char {
		border: .1rem solid $color-bg;
		background-color: $color-bg;
		border-radius: $radius-small;
		flex: 1 0 0;
		width: 4rem;
		height: 6rem;
		line-height: 5.8rem;
		text-align: center;
		font-size: 2.4rem;
		transition: border-color $transition-hover ease;

		&.disabled {
			opacity: .7;
		}

		+ .pininput-char {
			margin-left: 1rem;
		}
	}

	.pininput-info {
		margin-top: 2.7rem;
		font-size: 1.2rem;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		
		.info-resend {
			font-size: 1.2rem;
			color: $color-text-light;
			margin-left: 1.5rem;

			.hyperlink {
				@include font-bold;
				color: $color-primary;
			}
		}

		.info-timer {
			@include font-bold;
			font-size: 1.4rem;
		}
	}
}